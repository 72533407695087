import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import { BM, BM_CREATE_ALBUM, BM_MANAGE_ALBUMS_STORE } from './consts/routes';
import { EventType } from '@wix/platform-editor-sdk';
import gogApi from './services/gogApi';
import actions from './consts/editorActions';
import { createManifest } from './manifest';
import component from './components/AlbumsGOGOOI/.component.json';
import { deleteGog } from '@wix/bi-logger-photo-users/v2';

let appDefinitionId: string;
// TODO: When yoshi-flow-editor version updated to latesed change the type from any to FlowEditorSDK (github commit hash: e87a75c69f8530134e23e3fc77abf95e7fbe6bf4)
let sdk: any;
let t: any;
let instance: string;
let hasBusinessPackage: boolean = false;
let biLogger: any;

const SETTINGS_WIDTH = 404;
const SETTINGS_HEIGHT = 528;

const { id: gogWidgetId } = component;

export const editorReady: EditorReadyFn = async (
  _editorSDK,
  _appDefinitionId: string,
  platformOptions,
  flowAPI: EditorScriptFlowAPI,
) => {
  const { reportError, fedops, translations, httpClient, experiments } =
    flowAPI;
  appDefinitionId = _appDefinitionId;
  sdk = _editorSDK;
  t = translations.t;
  biLogger = platformOptions.essentials?.biLoggerFactory().logger();

  const { checkIfHasBusinessPackage } = gogApi(httpClient);

  const locale = await sdk.environment.getLocale(appDefinitionId);
  const metaSiteId = await sdk.info.getMetaSiteId(appDefinitionId);
  instance = await sdk.document.info.getAppInstance(appDefinitionId);

  const data = await checkIfHasBusinessPackage(metaSiteId, locale, instance);
  hasBusinessPackage = data ? data.result : false;

  try {
    if (platformOptions.firstInstall) {
      fedops.interactionStarted('install');
      // Place for your app initialization here. (Add pages to editor platform, init configs, etc);
      console.log('App was installed ✅');
      fedops.interactionEnded('install');
    }
  } catch (e: any) {
    reportError(e);
  }
};

export const getAppManifest = () => {
  const manifest = createManifest({
    t,
    hasBusinessPackage,
  });
  return manifest;
};

const getComponentRef = async (applicationId: number) => {
  const comps =
    (await sdk.tpa.app.getAllCompsByApplicationId(
      appDefinitionId,
      applicationId,
    )) || [];
  const albumsSectionComponent = comps[0] || {};
  return sdk.components.getById(appDefinitionId, {
    id: albumsSectionComponent.id,
  });
};

const getSettingsUrl = (appData: any) => {
  if (appData.settingsUrl?.indexOf('albums.wix.com') > -1) {
    if (appData.widgets[gogWidgetId]?.settings?.urlV2) {
      return appData.widgets[gogWidgetId]?.settings?.urlV2;
    }
  }
  return appData.settingsUrl;
};

const openAppSettings = async (
  shouldNavigate?: boolean,
  _componentRef?: any,
) => {
  const appData: any = await sdk.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  );

  const settingsUrl = getSettingsUrl(appData);
  const componentRef =
    _componentRef || (await getComponentRef(appData.applicationId));
  const photoAlbumsPage = await sdk.components.getPage(appDefinitionId, {
    componentRef,
  });
  if (shouldNavigate) {
    await sdk.document.pages.navigateTo(appDefinitionId, {
      pageRef: photoAlbumsPage,
    });
    await sdk.editor.selection.selectComponentByCompRef(appDefinitionId, {
      compsToSelect: [componentRef],
    });
    // component needs to be selected in order to open modal correctly
  }

  return sdk.editor.openComponentPanel(appDefinitionId, {
    url: settingsUrl,
    type: sdk.editor.PanelType.Settings,
    componentRef,
    width: SETTINGS_WIDTH,
    height: SETTINGS_HEIGHT,
    title: t('Dashboard_Main_Product_Name'),
    helpId: '73bedc32-fd06-4d8c-a8d3-afcfb80b8e18',
  });
};

export const onEvent = async ({ eventType, eventPayload }: any) => {
  if (eventType === EventType.appActionClicked) {
    switch (eventPayload.actionId) {
      case actions.CREATE_ALBUM_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, {
          url: BM_CREATE_ALBUM,
          closeOtherPanels: true,
        });
        break;
      case actions.MANAGE_ALBUMS_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, {
          url: BM,
          closeOtherPanels: true,
        });
        break;
      case actions.MANAGE_ALBUMS_STORE_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, {
          url: BM_MANAGE_ALBUMS_STORE,
          closeOtherPanels: true,
        });
        break;
      case actions.CUSTOMIZE_ALBUMS_PAGE_ACTION:
        await openAppSettings(true, eventPayload.componentRef);
        break;
      default:
        break;
    }
  }

  if (eventType === EventType.widgetGfppClicked) {
    switch (eventPayload.id) {
      case actions.MANAGE_ALBUMS_ACTION:
        sdk.editor
          .openDashboardPanel(appDefinitionId, {
            url: BM,
            closeOtherPanels: true,
          })
          .then(() => {
            sdk.document.tpa.app.refreshApp(appDefinitionId);
          });
        break;
      case actions.CUSTOMIZE_ALBUMS_PAGE_ACTION:
        await openAppSettings(false, eventPayload.componentRef);
        break;
      default:
        break;
    }
  }

  if (eventType === EventType.componentDeleted) {
    const pageRef = await sdk.pages.getCurrent();
    const pageData = await sdk.pages.data.get('', { pageRef });
    const { title } = pageData;

    biLogger?.report(
      deleteGog({
        gogId: eventPayload.componentRef.id,
        pageName: title,
      }),
    );
  }
};

// const handleBeforeRemove = async (origin: string, data: any) => {
//   console.log('app Deleteddddd');
// };

// export const exports = (editorSDK: EditorSDK): AppExposedApis => ({
//   editor: {
//     beforeRemoveApp: (data) => handleBeforeRemove('before', data),
//     removeApp: (data) => handleBeforeRemove('remove', data),
//     removeAppCompleted: (data) => handleBeforeRemove('remove complete', data),
//   },
// });
