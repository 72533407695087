import actions from './consts/editorActions';

import component from './components/AlbumsGOGOOI/.component.json';

const { id: widgetId } = component;
const newWidgetId = 'c889737e-3746-4f30-b729-4ac0d919216b';

export const SETTINGS_EV_ID = `${widgetId}:settings`;
export const DASHBOARD_EV_ID = `${widgetId}:dashboard`;

// It's app manifest structure.
// The app manifest describes how the application and its components behave
// in the Editor after the Editor script creates the component on the stage.
// Read more about this here:
// https://bo.wix.com/wix-docs/client/editor-platform/articles/app-manifests/app-manifest
const getWidgetStageData = (t: (key: string) => string) => {
  return {
    [widgetId]: {
      default: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: t('GFPP_MANAGE_LABEL'),
              actionId: actions.MANAGE_ALBUMS_ACTION,
              action_name: 'manageAlbums',
            },
            mainAction2: {
              label: t('GFPP_SETTINGS_LABEL'),
              actionId: actions.CUSTOMIZE_ALBUMS_PAGE_ACTION,
              action_name: 'settings',
            },
            iconButtons: {
              pinMode: 'HIDE',
              stretch: 'DEFAULT',
            },
            helpId: '73bedc32-fd06-4d8c-a8d3-afcfb80b8e18',
          },
        },

        nickname: widgetId,
      },
    },
    [newWidgetId]: {
      default: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: t('GFPP_MANAGE_LABEL'),
              actionId: actions.MANAGE_ALBUMS_ACTION,
              action_name: 'widgetManageAlbums',
            },
            mainAction2: {
              label: t('GFPP_SETTINGS_LABEL'),
              actionId: actions.CUSTOMIZE_ALBUMS_PAGE_ACTION,
              action_name: 'widgetSettings',
            },
            iconButtons: {
              pinMode: 'HIDE',
              stretch: 'DEFAULT',
            },
            helpId: '73bedc32-fd06-4d8c-a8d3-afcfb80b8e18',
          },
        },

        nickname: newWidgetId,
      },
    },
  };
};

export const createManifest = ({
  t,
  hasBusinessPackage,
}: {
  t: (key: string) => string;
  hasBusinessPackage: boolean;
}) => {
  const gfppData = { controllersStageData: getWidgetStageData(t) };
  return {
    ...gfppData,
    appDescriptor: {
      mainActions: [
        {
          title: t('App_Manager_Create_Album_CTA'),
          icon: 'appManager_addElementsAction',
          actionId: actions.CREATE_ALBUM_ACTION,
        },
        {
          title: t('App_Manager_Manage_Albums_CTA'),
          icon: 'appManager_settingsAction',
          actionId: actions.MANAGE_ALBUMS_ACTION,
        },
      ],
      customActions: [
        // remove upgrade store to prevent user's access
        // {
        //   title: t('App_Manager_Manage_Albums_Store_CTA'),
        //   actionId: actions.MANAGE_ALBUMS_STORE_ACTION,
        //   icon: 'appManager_settingsAction',
        //   type: 'dashboard',
        // },
        {
          title: t('App_Manager_Customize_Albums_Page_CTA'),
          actionId: actions.CUSTOMIZE_ALBUMS_PAGE_ACTION,
          icon: 'appManager_pagesAction',
          type: 'editorActions',
        },
      ],
      defaultActions:
        // remove upgrade store to prevent user's access
        // !hasBusinessPackage
        // ? {
        //     upgrade: {
        //       upgradeType: 'SITE_UPGRADE',
        //       upgradeText: t('App_Manager_Upgrade_Banner_Text'),
        //       upgradeLinkText: t('App_Manager_Upgrade_Banner_Link_Text'),
        //     },
        //     learnMoreKB: '3382633d-8c8c-4b05-bc10-009021ecf554',
        //   }
        // :
        {
          learnMoreKB: '3382633d-8c8c-4b05-bc10-009021ecf554',
        },
    },
  };
};
